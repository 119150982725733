import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Layout, Seo } from 'components';
import ScrollableAnchor from 'react-scrollable-anchor'; // goToAnchor,

import SourceEmitter from 'libs/emitter';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';

import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

/*
import TableBlock from '../../../components/Content/TableBlock'
import AlertBox from '../../../components/Content/AlertBox'
import HeadlineBar from '../../../components/Content/HeadlineBar'
import imgHero from '../../../../assets/images/nascobal-hcp-home-hero.png'
import imgHeroMobile from '../../../../assets/images/nascobal-hcp-home-hero-mobile.png'
import imgOneInSix from '../../../../assets/images/nascobal-callout-circle1.png'
import imgMeanConcentration from '../../../../assets/images/vitaminb12-mean-concentration.png'
import imgSerumMean from '../../../../assets/images/serumb12-mean.png'
import step1 from '../../../../assets/images/nasal-spray-step1.png'
import step2 from '../../../../assets/images/nasal-spray-step2.png'
import step3 from '../../../../assets/images/nasal-spray-step3.png'
import nascobalCoupon from '../../../../assets/images/nascobal-coupon.png'
import enrollmentPDF from '../../../../assets/enrollment-form.pdf'
import brochurePDF from '../../../../assets/Patient-Ed-Brochure.pdf'
*/
import './request_a_sales_rep.scss';

const REST_URL = process.env.NAS_REQUEST_A_REP_ENDPOINT;

// const RECAPTCHA_SITE_KEY = '6LdhuSkdAAAAAMYTbK0MiEbfH5RtcywhyemEwgx2';
const RECAPTCHA_SITE_KEY = '6Lfnl74dAAAAAGI5DvYmNlaLLsbtWTL2K6HGuqhM';

// INITIALIZATIONS
let Subscription = null;

class RequestARepPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      email: '',
      emailError: '',
      firstName: '',
      firstNameError: false,
      lastName: '',
      lastNameError: false,
      practiceName: '',
      practiceNameError: false,
      specialty: '',
      specialtyError: false,
      npi: '',
      npiError: false,
      reasonForVisit: '',
      reasonForVisitError: false,
      address1: '',
      address2: '',
      address1Error: false,
      city: '',
      cityError: false,
      state: '',
      stateError: false,
      zip: '',
      zipError: false,
      phone: '',
      phoneError: false,
      buttonDisabled: '',
      checkingReCaptchaForSubmit: false,
      ReCaptchaToken: '',
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendFormData = this.sendFormData.bind(this);
    this.validateEmpty = this.validateEmpty.bind(this);
    this.setResponseParams = this.setResponseParams.bind(this);
    this.formatParams = this.formatParams.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  componentDidMount() {
    this.setResponseParams();

    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      // eslint-disable-next-line no-unused-expressions
      window?.location?.replace('/vitaminb12/hcp#top');
    });
    loadReCaptcha(RECAPTCHA_SITE_KEY, () => {
      console.log('loading recaptcha');
    });
  }

  componentWillUnmount() {
    Subscription && Subscription.remove();

    const script = document.getElementById('recaptcha-script');
    if (script) {
      script.remove();
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
  }

  verifyCallback = async recaptchaToken => {
    if (this.state.checkingReCaptchaForSubmit) {
      await this.setState({
        checkingReCaptchaForSubmit: false,
        ReCaptchaToken: recaptchaToken,
      });
      await this.handleSubmit();
    }
  };

  updateRecaptchaToken = () => {
    this.recaptcha.execute();
  };

  handleSubmitForCaptcha = async () => {
    await this.setState({ checkingReCaptchaForSubmit: true });
    this.updateRecaptchaToken();
  };

  onFormSubmit(e) {
    e.preventDefault();
    if (this.state.buttonDisabled) {
      return;
    }
    this.setState({
      buttonDisabled: true,
    });
    // check that all fields have values
    const emailREGX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // exit if validation fails
    if (
      this.validateEmpty('firstName') ||
      this.validateEmpty('lastName') ||
      this.validateEmpty('practiceName') ||
      this.validateEmpty('specialty') ||
      this.validateEmpty('npi') ||
      this.validateEmpty('reasonForVisit') ||
      this.validateEmpty('email') ||
      this.validateEmpty('address1') ||
      this.validateEmpty('city') ||
      this.validateEmpty('state') ||
      this.validateEmpty('zip') ||
      this.validateEmpty('phone')
    ) {
      return;
    }

    if (!emailREGX.test(this.state.email)) {
      this.setState({
        alert: {
          show: true,
          status: 'critical',
          text: 'Please provide a valid email address',
        },
        emailError: true,
        buttonDisabled: false,
      });
      return;
    }

    const data = JSON.stringify({
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      practiceName: this.state.practiceName,
      specialty: this.state.specialty,
      npi: this.state.npi,
      reasonForVisit: this.state.reasonForVisit,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      phone: this.state.phone,
    });

    this.setState(
      {
        alert: { show: true, status: 'unknown', text: 'Sending...' },
        submitted: true,
      },
      this.sendFormData(data),
    );
  }

  setResponseParams() {
    const responseObj = window.location.hash
      .substr(1)
      .split('&')
      .map(el => el.split('='))
      .reduce((pre, cur) => {
        pre[cur[0]] = cur[1];
        return pre;
      }, {});
    this.setState({
      submited: responseObj.thankyou,
    });
  }

  // validators
  validateEmpty(key) {
    if (this.state[key] === '') {
      this.setState({
        [`${key}Error`]: true,
        alert: {
          show: true,
          status: 'critical',
          text: 'Please fill out all required fields.',
        },
        buttonDisabled: false,
      });
      return true;
    } else {
      this.setState({
        [`${key}Error`]: '',
      });
    }
  }

  formatParams(params) {
    return Object.keys(params)
      .map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');
  }

  handleChange(key, event) {
    this.setState({ [key]: event.target.value });
  }

  handleDropdownChange(key, event) {
    // console.log('this is dropdown', event.target.value);
    this.setState({ [key]: event.target.value });
  }

  sendFormData(data) {
    // Send the form data.
    // eslint-disable-next-line no-undef
    const xmlhttp = new XMLHttpRequest();

    xmlhttp.open('POST', REST_URL, true);
    xmlhttp.setRequestHeader('Accept', 'application/json');
    xmlhttp.setRequestHeader('Content-Type', 'application/json');

    xmlhttp.send(data);

    const _this = this;

    xmlhttp.onreadystatechange = function () {
      // console.log(xmlhttp.readyState)

      if (xmlhttp.readyState < 4)
        _this.setState({
          alert: { show: true, status: 'unknown', text: 'Loading.....' },
        });
      else if (xmlhttp.readyState === 4) {
        // 200 - 299 = successful
        if (xmlhttp.status === 200 || xmlhttp.status < 300) {
          _this.setState({
            alert: {
              show: true,
              status: 'ok',
              text: 'Thank you! Your answers have been submitted.',
            },
          });
          const scroller = document.getElementById('ReturnForm');
          // eslint-disable-next-line no-undef
          scrollTo(scroller, 100, 1000);
          const isGA = typeof window.ga !== 'undefined';

          if (isGA) {
            window.ga(
              'send',
              'event',
              'Button-Submit',
              'click submit on request a rep',
              'Submit Form',
            );
          }

          _this.setState({
            email: '',
            emailError: '',
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            practiceName: '',
            practiceNameError: '',
            specialty: '',
            specialtyError: '',
            npi: '',
            npiError: '',
            reasonForVisit: '',
            reasonForVisitError: '',
            address1: '',
            address2: '',
            address1Error: '',
            city: '',
            cityError: '',
            state: '',
            stateError: '',
            zip: '',
            zipError: '',
            phone: '',
            phoneError: '',
            buttonDisabled: '',
            formComplete: true,
            submited: true,
          });
        } else {
          _this.setState({
            alert: {
              show: true,
              status: 'critical',
              text: 'An unexpected error happened, please try again',
            },
          });
        }
      }
    };
  }

  render() {
    const { submited } = this.state;

    // const brandNames = {
    //   NASCOBAL: this.props.data.site.siteMetadata.brandNames.nascobal,
    //   NUTRITION: this.props.data.site.siteMetadata.brandNames.nutrition,
    //   BARIACTIV: this.props.data.site.siteMetadata.brandNames.bariactiv,
    // };

    // const brandNames = {
    //   NASCOBAL: 'NASCOBAL<sup className="brand-reg-mark">&reg;</sup>',
    //   NUTRITION:
    //     '<nobr>Nutrition Direct<span className="brand-trade-mark">&trade;</span></nobr>',
    //   BARIACTIV: 'BariActiv<sup className="brand-reg-mark">&reg;</sup>',
    // };

    // hide n show containers if submited
    const thankYouContainerStyles = submited
      ? 'thank-you-message-container open'
      : 'thank-you-message-container closed';
    const formContainerStyles = submited
      ? 'request-a-rep-form closed'
      : 'request-a-rep-form open';

    const pageTitle = 'NASCOBAL® | Request a Sales Representative';
    const pageDescription = 'Have a sales representative contact your practice';
    const pageKeywords = ['Nascobal, Vitamin B12, Nasal Spray'];
    const renderPage = () => {
      return (
        <div id="nascobal-hcp-request-a-sales-rep">
          <ContentBlock id="cblock-vitaminb12">
            <Grid
              fluid
              style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
            >
              {/* <ScrollableAnchor id="vitamin_b12_deficiency">
                <div />
              </ScrollableAnchor> */}
              <Row>
                <Col xs={12} md={8}>
                  <ColorBlock white>
                    <div className={formContainerStyles}>
                      <h2>
                        REQUEST A NASCOBAL<sup>&reg;</sup> SALES REPRESENTATIVE
                      </h2>
                      <p>
                        This is intended for U.S. healthcare providers only.
                      </p>
                      <p>Please enter your contact information below.</p>
                      <p>All fields are required unless marked optional.</p>
                      <form
                        id="request-a-rep-form"
                        role="form"
                        method="POST"
                        action=""
                        className="request-a-rep-form-container"
                      >
                        <div
                          className={
                            this.state.firstNameError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="First_Name">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="First_Name"
                            value={this.state.firstName}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'firstName')}
                            placeholder="Enter First Name"
                          />
                        </div>
                        <div
                          className={
                            this.state.lastNameError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="Last_Name">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="Last_Name"
                            value={this.state.lastName}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'lastName')}
                            placeholder="Enter Last Name"
                          />
                        </div>
                        <div
                          className={
                            this.state.practiceNameError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="Practice_Name">Practice Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="Practice_Name"
                            value={this.state.practiceName}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(
                              this,
                              'practiceName',
                            )}
                            placeholder="Enter Practice Name"
                          />
                        </div>
                        <div
                          className={
                            this.state.specialtyError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label id="Specialty_lbl" htmlFor="Specialty">
                            Specialty
                          </label>
                          <div
                            id="container_specialty_select"
                            className="container_select"
                          >
                            <select
                              className="form-control"
                              value={this.state.specialty}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={this.handleDropdownChange.bind(
                                this,
                                'specialty',
                              )}
                            >
                              <option value="Select" defaultValue="">
                                Select
                              </option>
                              <option value="Endocrinologist">
                                Endocrinologist
                              </option>
                              <option value="Family Practitioner">
                                Family Practitioner
                              </option>
                              <option value="Gastroenterologist">
                                Gastroenterologist
                              </option>
                              <option value="General Practitioner">
                                General Practitioner
                              </option>
                              <option value="PGeneral Surgeon">
                                General Surgeon
                              </option>
                              <option value="Internal Medicine">
                                Internal Medicine
                              </option>
                              <option value="Neurologist">Neurologist</option>
                              <option value="Nurse Practitioner">
                                Nurse Practitioner
                              </option>
                              <option value="OB/GYN">OB/GYN</option>
                              <option value="Physician Assitants">
                                Physician Assistant
                              </option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.npiError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="NPI">NPI</label>
                          <input
                            type="text"
                            className="form-control"
                            id="NPI"
                            value={this.state.npi}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'npi')}
                            placeholder="Enter NPI"
                          />
                        </div>
                        <div
                          className={
                            this.state.reasonForVisitError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label id="Visit_lbl" htmlFor="Visit">
                            Reason for Visit
                          </label>
                          <div
                            id="container_visit_select"
                            className="container_select"
                          >
                            <select
                              className="form-control"
                              value={this.state.reasonForVisit}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={this.handleDropdownChange.bind(
                                this,
                                'reasonForVisit',
                              )}
                            >
                              <option value="Select" defaultValue="">
                                Select
                              </option>
                              <option value="Additional Information">
                                Additional Information
                              </option>
                              <option value="Patient Education Materials">
                                Patient Education Materials
                              </option>
                              <option value="Samples">
                                Sample Request Information
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.emailError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="Email">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            id="Email"
                            value={this.state.email}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'email')}
                            placeholder="Enter Email"
                          />
                        </div>
                        <div
                          className={
                            this.state.address1Error
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="Address_1">Address 1</label>
                          <input
                            type="text"
                            className="form-control"
                            id="Address_1"
                            value={this.state.address1}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'address1')}
                            placeholder="Enter Address"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="Address_2">
                            Address 2 (optional)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Address_2"
                            value={this.state.address2}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'address2')}
                            placeholder="Enter Address 2"
                          />
                        </div>
                        <div
                          className={
                            this.state.cityError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="City">City</label>
                          <input
                            type="text"
                            className="form-control city"
                            id="City"
                            value={this.state.city}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'city')}
                            placeholder="Enter City"
                          />
                        </div>
                        <div
                          className={
                            this.state.stateError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label id="state_lbl" htmlFor="State">
                            State
                          </label>
                          <div
                            id="container_state_select"
                            className="container_select"
                          >
                            <select
                              id="State"
                              name="state"
                              className="form-control state"
                              value={this.state.state}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={this.handleDropdownChange.bind(
                                this,
                                'state',
                              )}
                            >
                              <option value="">Select</option>
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.zipError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="Zipcode">ZIP</label>
                          <input
                            type="text"
                            className="form-control zip"
                            id="Zip_Code"
                            value={this.state.zip}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'zip')}
                            placeholder="Enter ZIP"
                          />
                        </div>
                        <div
                          className={
                            this.state.phoneError
                              ? 'form-group error'
                              : 'form-group'
                          }
                        >
                          <label htmlFor="Phone_Number">Phone Number</label>
                          <input
                            type="text"
                            className="form-control phone"
                            id="Phone_Number"
                            value={this.state.phone}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={this.handleChange.bind(this, 'phone')}
                            placeholder="Enter Phone Number"
                          />
                        </div>

                        <button
                          className="nascobal-btn btn-default ns-btn-request-rep"
                          type="button"
                          onClick={this.onFormSubmit}
                          value="Submit"
                        >
                          REQUEST A REP
                        </button>
                        <ReCaptcha
                          ref={ref => (this.recaptcha = ref)}
                          sitekey={RECAPTCHA_SITE_KEY}
                          verifyCallback={this.verifyCallback}
                        />
                      </form>
                      <p className="footnote footnote-no-indent">
                        The privacy and security of your personal information is
                        important to Endo Pharmaceuticals Inc. Please note that
                        by providing your name, address or other information,
                        you are giving Endo Pharmaceuticals and companies
                        working with us permission to communicate with you via
                        traditional mail, email, telephone or text about
                        NASCOBAL<sup>&reg;</sup> and other Endo Pharmaceuticals
                        products, programs and services. We will not sell or
                        transfer your name, address or other personally
                        identifiable information about you to any party for its
                        own marketing use. For further information about Endo
                        Pharmaceuticals privacy practices, please review the
                        NASCOBAL.com{' '}
                        <a
                          href="https://www.endo.com/privacy-legal"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                    {/* THANK YOU MSG */}
                    <div className={thankYouContainerStyles}>
                      <h2>
                        THANK YOU FOR YOUR INTEREST IN NASCOBAL
                        <sup>&reg;</sup>
                      </h2>
                      <p>
                        Your information has been received and will be submitted
                        to the appropriate recipient.
                      </p>
                    </div>
                  </ColorBlock>
                </Col>
              </Row>
            </Grid>
          </ContentBlock>
          <ScrollableAnchor id="isi_anchor">
            <div />
          </ScrollableAnchor>
        </div>
      );
    };

    return (
      <Layout indication="hcp" className="hcp-request-rep">
        <Seo
          pageTitle={pageTitle}
          pageDescription={pageDescription}
          pageKeywords={pageKeywords}
        />
        <div className="body-content-container">
          <div className="body-content gutter-all">{renderPage()}</div>
        </div>
      </Layout>
    );
  }
}

export default RequestARepPage;
